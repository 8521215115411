<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />

    <div class="main-con">
      <div class="level-con">
        <div class="row level-row justify-content-center align-items-center">
          <div class="col">
            <div class="level-status target-card-lable">
              Practices Completed for {{ currentYear - 1 }}
            </div>
            <div class="target-card-value">{{ actionsCompletedPrevYear }}</div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              #Practices to Next Level
            </div>
            <div class="target-card-value">{{ actionsNextLevel }}</div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              Target #Practices for {{ currentYear }}
            </div>
            <div class="target-card-value">{{ targetActions }}</div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              Total Practices by end {{ currentYear }}
            </div>
            <div class="target-card-value">{{ totalActions }}</div>
          </div>
        </div>
      </div>

      <div class="trget-sum">Forecast Setting Summary by Pillar</div>
      <div class="
          row
          level-row-individual
          justify-content-center
          align-items-center
        ">
        <div class="col action-block action-to-dis">
          <div class="target-status">Practices to Distribute</div>
          <div class="info-card-value">{{ targetActions }}</div>
        </div>
        <div class="col action-block action-dis">
          <div class="info-card-lable">Practices Distributed</div>
          <div class="info-card-value" :class="markRed ? 'target-summary-value-red' : ''">
            {{ actionDistribute }}
          </div>
        </div>
      </div>
      <b-table id="edit-target-table" class="target-table gn-table-gn" responsive :items="targetValues"
        :fields="targetFields">
        <template #cell(item)="row">
          {{ row.value }}
        </template>
        <template #cell()="row">
          <span v-if="row.field.status === 'N'"></span>
          <div v-else-if="row.item.item.indexOf('Overall ') !== -1
            " class="input-div">
            <input class="input-f" type="number" v-model="values[row.field.key]" min="0"
              :disabled="disable[row.field.key]" @input="changingF()" pattern="^[0-9]" @keydown="isNumber" />
          </div>
          <span v-else>
            {{ row.item[row.field.key] }}
          </span>

        </template>
      </b-table>

      <div class="btn-group">
        <div>
          <b-button class="btn cancel-save-btn ed-st-btn" pill variant="outline-primary" @click="cancel()">
            Cancel
          </b-button>
        </div>
        <div>
          <b-button class="btn cancel-save-btn ed-st-btn" pill variant="outline-primary" :disabled="!accessRights"
            @click="onSaveSubmit('False')">
            Save
          </b-button>
        </div>
        <div>
          <b-button class="btn save-submit-btn ed-st-btn" pill block :disabled="!accessRights"
            @click="onSaveSubmit('True')">
            Save & Submit
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import Helper from "../../../common/helper";
import Role from "../../../common/constants/roles";
export default {
  name: "TargetSetting",
  components: {
    Breadcrumb,
    Loader,
  },
  data() {
    return {
      roles: Role,
      disable: {},
      values: {},
      showLoader: true,
      plantId: "",
      targetActions: "-",
      totalActions: "-",
      actionsCompletedPrevYear: "-",
      actionsNextLevel: "-",
      currentYear: "",
      prevYear: "",
      actionDistribute: 0,
      pec: 0,
      csd: 0,
      zlc: 0,
      wpo: 0,
      dec: 0,
      re: 0,
      fi: 0,
      qp: 0,
      npi: 0,
      pf: 0,
      lpo: 0,
      eem: 0,
      sa: 0,
      markRed: false,
      targetFields: [
        {
          key: "item",
          label: "",
          sortable: false,
        },
        {
          key: "pec",
          label: "PEC",
          variant: "blue",
          sortable: false,
        },
        {
          key: "csd",
          label: "CSD",
          variant: "blue",
          sortable: false,
        },
        {
          key: "zlc",
          label: "ZLC",
          variant: "blue",
          sortable: false,
        },
        {
          key: "wpo",
          label: "WPO",
          variant: "blue",
          sortable: false,
        },
        {
          key: "dec",
          label: "DEC",
          variant: "blue",
          sortable: false,
        },
        {
          key: "re",
          label: "RE",
          variant: "blue",
          sortable: false,
        },
        {
          key: "fi",
          variant: "blue",
          label: "FI",
          sortable: false,
        },
        {
          key: "qp",
          label: "QP",
          variant: "blue",
          sortable: false,
        },
        {
          key: "ogw",
          label: "OGW",
          variant: "light",
          sortable: false,
        },
        {
          key: "pf",
          label: "PF",
          variant: "light",
          sortable: false,
        },
        {
          key: "eem",
          label: "EEM",
          variant: "light",
          sortable: false,
        },
        {
          key: "npi",
          label: "NPI",
          variant: "light",
          sortable: false,
        },
      ],
      targetValues: [
        {
          item: "Current Pillar Score",
          pec: 0,
          csd: 0,
          zlc: 0,
          wpo: 0,
          dec: 0,
          re: 0,
          fi: 0,
          qp: 0,
        },
        {
          item: "Current Maturity Level",
          pec: 0,
          csd: 0,
          zlc: 0,
          wpo: 0,
          dec: 0,
          re: 0,
          fi: 0,
          qp: 0,
        },
        {
          item: "#Practices to Next Level",
          pec: 0,
          csd: 0,
          zlc: 0,
          wpo: 0,
          dec: 0,
          re: 0,
          fi: 0,
          qp: 0,
        },
        {
          item: "Overall #Target Practices 2022",
          pec: 0,
          csd: 0,
          zlc: 0,
          wpo: 0,
          dec: 0,
          re: 0,
          fi: 0,
          qp: 0,
        },
        {
          item: "#Committed Practices",
          pec: 0,
          csd: 0,
          zlc: 0,
          wpo: 0,
          dec: 0,
          re: 0,
          fi: 0,
          qp: 0,
        },
      ],
      pillarList: [],
    };
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 2,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Edit Target Number Details",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Edit Target Number Details", primary: false },
      ],
    });
  },
  created() {
    this.plantId = this.$route.params.id;
    this.currentYear = Helper.currentYear();
    this.prevYear = Helper.currentYear() - 1;
    this.getTargetActionDetails();
    this.getTargetSettingSummary();
    this.getPillarList();
  },
  computed: {

    accessRights() {
      return (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) || this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER))
    },
  },
  methods: {
    getTargetActionDetails() {
      ApiCalls.httpCallDetails(Api.ACTIONDETAILS + this.plantId, "get").then(
        (data) => {
          this.targetActions = data.data.targetAction;
          this.totalActions = data.data.totalAction;
          this.actionsCompletedPrevYear = data.data.actionCompleted;
          this.actionsNextLevel = data.data.actionsToNextLevel;
          this.currentYear = data.data.currentYear;
        }
      );
    },
    getTargetSettingSummary() {
      ApiCalls.httpCallDetails(Api.TARGETSUMMARY + this.plantId, "get").then(
        (data) => {
          console.log(data);
          if (data.success || data.sucess) {
            data.data.targetField.forEach((element) => {
              this.disable[element.key] = element.status == "N" ? true : false;
              element.variant = element.status == "N" ? "light" : "blue";
              // element.key=element.key.toLowerCase()
            });
            data.data.targetValue.forEach((element) => {
              if (element.item.indexOf("Overall ") !== -1) {
                element.item += " " + Helper.currentYear();
                for (const key in this.disable) {
                  if (
                    Object.hasOwnProperty.call(this.disable, key) &&
                    key !== "item"
                  ) {
                    this.values[key] = element[key];
                  }
                }
              }
            });
            data.data.targetField.forEach((i) => {
              if (i.key === "item") {
                i.status = "Y";
              }
            });
            this.targetFields = data.data.targetField;
            this.targetValues = data.data.targetValue;
          }
          this.changingF();
          this.showLoader = false;
        }
      );
    },
    cancel() {
      this.$router.push("/forecast-setting");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changingF() {
      // this.actionDistribute
      let ad = 0;
      for (const key in this.values) {
        if (Object.hasOwnProperty.call(this.values, key)) {
          ad += Number(this.values[key]);
        }
      }

      this.actionDistribute = ad;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.markRed = this.targetActions > ad;
    },

    getPillarList() {
      ApiCalls.httpCallDetails(Api.PILLARLIST + this.plantId, "get").then(
        (data) => {
          this.pillarList = data.data;
        }
      );
    },
    getPillarId(name) {
      for (let el of this.pillarList) {
        const element = el;
        if (element.pillarCode === name) {
          return element.pillarId;
        }
      }
    },
    onSaveSubmit(submitStatusValue) {
      let pillarArrData = [];
      for (const key in this.values) {
        if (Object.hasOwnProperty.call(this.values, key)) {
          const element = this.values[key];
          let obj = {
            pillarId: this.getPillarId(key),
            count: Number(element ? element : 0),
          };
          pillarArrData.push(obj);
        }
      }

      let requestPayload = {
        plantId: Number(this.plantId),
        submitStatus: submitStatusValue,
        pillarData: pillarArrData,
        // targetActionsCounts: this.targetActions,
      };
      console.log("requestPayload", requestPayload);
      this.showLoader = true;

      ApiCalls.httpCallDetails(
        Api.UPDATEDISTRIBUTEACTION,
        "post",
        requestPayload
      ).then((data) => {
        console.log("data", data);
        this.showLoader = false;
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style scoped>
.mat-info-con {
  padding: 2rem;
  display: flex;
  align-items: center;
}

.plant-dd {
  width: 30%;
}

.date-setng {
  width: 12rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-con {
  padding-left: 15px;
  padding-right: 15px;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
}

.level-row-individual {
  text-align: center;
  /* background: #eff3ff;
  border: 1px solid #ebecef; */
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 40rem;
  margin-left: 2rem;
}

.level-status {
  color: #454545;
}

.target-status {
  color: #414141;
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
}

.level-date {
  opacity: 0.7;
}

.cml-by {
  width: 70%;
  font-family: "BarlowSb", sans-serif;
  font-size: 1.333rem;
  color: #d35757;
  text-align: right;
}

.modify-date {
  position: absolute;
  top: 11rem;
  right: 4.5rem;
}

.setng-text {
  margin-left: 1rem;
}

.edit-btn {
  height: 2.2rem;
  padding-top: 0.3rem !important;
  margin-left: 0.5rem;
}

.distribute-con {
  text-align: center;
  padding: 1rem;
}

.distribute-btn {
  height: 3rem;
  width: 14rem;
}

.trget-sum {
  font-size: 1.333rem;
  font-family: "BarlowSb", sans-serif;
  color: #234372;
  padding: 2rem;
  padding-bottom: 1rem;
}

.action-block {
  border-radius: 7px;
  width: 13rem;
  margin-right: 1rem;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
}

.action-to-dis {
  background: #fffce3;
}

.action-dis {
  background: #fff2f2;
  border: 1px solid #eaeaea;
}

.target-summary-value {
  font-size: 1.583rem;
  font-family: "BarlowM", sans-serif;
}

.target-summary-value-red {
  color: #e65959;
}

.save-submit-btn {
  background-color: #274ba7;
  border-color: #274ba7 !important;
  margin-top: 0.4rem;
}

.save-submit-btn:hover {
  background-color: #258cf3 !important;
  border-color: #258cf3 !important;
}

.save-submit-btn:active {
  background-color: #258cf3 !important;
  border-color: #258cf3 !important;
}

.save-submit-btn:focus {
  box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

.sq {
  height: 50px;
  width: 50px;
}

.btn-group {
  display: flex;
  align-content: center;
  justify-content: center;
}

.btn {
  margin: 2rem 1rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
}

.input-div {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* width: 50px; */
}

.input-f {
  width: 50px;
  text-align: center;
  font-size: 1.33rem;
  border: #313131 1px solid;
}

.target-table .table-blue,
.target-table .table-blue>td,
.target-table .table-blue>th {
  background-color: #fafaf0;
}

.blueClass {
  background: #fafaf0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .modify-date {
    top: 37rem !important;
  }

  .main-con {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 767px) {
  .modify-date {
    top: 40rem !important;
  }
}
</style>